import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import Header from "./header";
import Footer from "./footer";
import "../styles/index.scss";
import layoutStyles from "./layout.module.scss";

library.add(fab, faCheckSquare, faCoffee);

const Layout = props => {
  return (
    <div className={layoutStyles.container}>
      <div className={layoutStyles.content}>
        <Header />
        <div class="container">{props.children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
