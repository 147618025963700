import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <footer>
      <div class="container">
        <div class="social">
          <div class="row">
            <div class="col-md-4 offset-md-4">
              <a href="https://www.linkedin.com/in/ericcogan">
                <FontAwesomeIcon
                  icon={["fab", "linkedin"]}
                  style={{ color: "#444" }}
                />
              </a>
              <a href="https://github.com/ecogan">
                <FontAwesomeIcon
                  icon={["fab", "github"]}
                  style={{ color: "#444" }}
                />
              </a>
              <a href="https://www.producthunt.com/@ericcogan">
                <FontAwesomeIcon
                  icon={["fab", "product-hunt"]}
                  style={{ color: "#444" }}
                />
              </a>
              <a href="https://twitter.com/ericcogan">
                <FontAwesomeIcon
                  icon={["fab", "twitter"]}
                  style={{ color: "#444" }}
                />
              </a>
              <a href="https://www.instagram.com/ericcogan/">
                <FontAwesomeIcon
                  icon={["fab", "instagram"]}
                  style={{ color: "#444" }}
                />
              </a>
            </div>
          </div>
          <div class="legal">
            &copy; {new Date().getFullYear()} Eric Cogan. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
