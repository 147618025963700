import React from "react";
import { Link } from "gatsby";
import headerStyles from "./header.module.scss";

const Header = () => {
  return (
    <div className={headerStyles.background}>
      <div class="container">
        <header>
          <div className={headerStyles.gears}>
            <img src="../img/1.png" class="rotate slow level-1" alt="" />
            <img src="../img/2.png" class="rotate fast level-2" alt="" />
            <img
              src="../img/3.png"
              class="hidden-xs  rotate slow level-3"
              alt=""
            />
            <img
              src="../img/2.png"
              class="hidden-xs rotate medium level-3"
              alt=""
            />
            <img
              src="../img/3.png"
              class="hidden-xs rotate fast level-1"
              alt=""
            />
          </div>
          <div class="row">
            <div class="col-xs-3 col-sm-3 col-md-6 col-lg-6">
              <div className={headerStyles.name}>
                <Link to="/" title="Eric Cogan">
                  <span class="e">E</span>
                  <span class="c">C</span>
                </Link>
              </div>
            </div>
            <div class="header-nav col-xs-9 col-sm-9 col-md-6 col-lg-6">
              <nav>
                <ul>
                  {/* <li><Link to="/blog" className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}>Blog</Link></li> */}
                  <li>
                    <Link
                      to="/about"
                      className={headerStyles.navItem}
                      activeClassName={headerStyles.activeNavItem}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/blog"
                      className={headerStyles.navItem}
                      activeClassName={headerStyles.activeNavItem}
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      className={headerStyles.navItem}
                      activeClassName={headerStyles.activeNavItem}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;
